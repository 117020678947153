
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonNote,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';
import { reactive } from '@vue/reactivity';

import DatePicker from '@/components/inputs/DatePicker.vue';
import UserSelect from '@/components/selects/UserSelect.vue';
import { createEmployeeDay } from '@/db';
import { dateToString } from '@/helpers';
import router from '@/router';
import store from '@/store';

export default {
  name: 'Select Date',
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    UserSelect,
    IonButton,
    IonNote,
    IonCard,
    DatePicker,
  },
  props: {
    employeeID: String,
    showSelect: Boolean,
  },
  setup(props: any) {
    const state = reactive({
      employeeID: props.employeeID == 'new' ? '' : props.employeeID,
      date: dateToString(new Date()),
    });

    const create = async () => {
      if (state.employeeID && state.date) {
        await createEmployeeDay(state.date, state.employeeID);
        router.replace({
          name: `Employee Day / ${store.state.userType}`,
          params: { userID: state.employeeID, id: state.date },
        });
      } else throw Error('Please select an employee and date.');
    };

    return {
      router,
      state,
      store,
      create,
    };
  },
};
